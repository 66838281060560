@import 'styles/theme';

.form {
  position: relative;
}

.input {
  position: relative;
  position: relative;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  background-color: transparent;

  input {
    height: theme($spacings, 50);
    margin-left: 1px;
    margin-top: 1px;
    margin-bottom: 1px;
    margin-right: 1px;
    padding-left: 0px;
    padding-right: 60px;
    border: none;
    box-sizing: border-box;
    background-color: transparent;

    width: 100%;

    @include from('lg') {
      height: unset;
      padding-top: theme($spacings, 8);
      padding-bottom: theme($spacings, 8);
      width: 100%;
    }

    &::placeholder {
      text-transform: uppercase;
    }

    &:focus-visible {
      outline: none;
    }
  }
}

.submitButton {
  cursor: pointer;
  width: auto;
  border: none;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .spinner {
    width: theme($spacings, 15);
    padding: theme($spacings, 10) theme($spacings, 20);
  }

  .submit {
    border: none;
    display: flex;
    padding-top: theme($spacings, 20);
    padding-bottom: theme($spacings, 20);
    padding-left: theme($spacings, 35);

    @include from('md') {
      padding-top: theme($spacings, 8);
      padding-bottom: theme($spacings, 8);
      padding-left: theme($spacings, 8);
    }

    &.textSubmit {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      padding-right: theme($spacings, 10);
    }
  }
}

.gdpr {
  margin-top: theme($spacings, 10);
}

.gdprText {
  margin-top: theme($spacings, 10);

  a {
    text-decoration: underline;
  }
}

.gdpr,
.gdprText {
  opacity: 0;
  pointer-events: none;

  &.visible {
    pointer-events: all;
    opacity: 1;
  }
}

.result {
  margin-top: theme($spacings, 10);
  text-align: left;
  padding-left: theme($spacings, 16);
  padding-right: theme($spacings, 16);
}

.errorList {
  margin-right: theme($spacings, 30);
  text-align: left;
}

// Themes
.isNotInline {
  .input {
    flex-direction: column;
    border: none;

    input {
      border: 1px solid theme($colors, 'gray');
      padding-left: theme($spacings, 16);
      padding-right: theme($spacings, 16);

      &::placeholder {
        text-transform: unset;
      }
    }
  }

  .errorList {
    margin-top: theme($spacings, 10);
    margin-right: 0;
    text-align: left;
  }

  .submitButton {
    position: relative;
    right: auto;
    width: 100%;

    .submit {
      align-items: center;
      justify-content: center;
      margin-top: theme($spacings, 40);

      &.textSubmit {
        text-align: center;
        width: 100%;
        padding-right: 0;
        padding: theme($spacings, 11) theme($spacings, 10);
      }
    }
  }
}
