@import 'styles/theme';

.PushNewsletter {
  position: relative;
  width: 100%;
  padding-top: theme($spacings, 40);
  padding-bottom: theme($spacings, 40);

  @include from('md') {
    display: flex;
    align-items: center;
    height: 440px;
    box-sizing: border-box;
    padding-top: theme($spacings, 160);
    padding-bottom: theme($spacings, 160);
  }

  .image {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  .grid {
    position: relative;
    z-index: 1;
  }

  .top {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    grid-column: 2/-2;
    margin-bottom: theme($spacings, 10);

    @include from('md') {
      margin-bottom: theme($spacings, 25);
      grid-column: 9/-9;
    }
  }

  .body {
    grid-column: 1/-1;

    @include from('md') {
      grid-column: 10/-10;
    }
  }

  .title,
  .subtitle {
    color: theme($colors, 'white');
  }
}
